import { useEffect } from "react";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import JobOfferConverter from "src/components/tools/job-offer-converter/JobOfferConverter";
import { usePollIframeHeight } from "src/hooks/useIframeEvents";

const JobOfferConverterView = ({ t }: Translation) => {
	usePollIframeHeight();

	useEffect(() => {
		localStorage.setItem("referral_type", "JOB_OFFER_CONVERTER");
	}, []);

	return (
		<div id="tools" className="flex flex-col flex-1">
			<JobOfferConverter />
		</div>
	);
};

JobOfferConverterView.locale = {
	nl: {},
	en: {},
};

export default withTranslation(JobOfferConverterView);
