import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import Breadcrumb from "src/components/Breadcrumb";
import Card from "src/components/Card";
import StepsLine from "src/components/StepsLine";
import Layout from "src/components/layout/Layout";
import ChristmasGiftBanner from "src/components/promotion/banners/ChristmasGiftBanner";
import TaskForm from "src/components/tasks/TaskForm";
import StudentBanner from "src/components/tasks/TaskForm/elements/StudentBanner";
import useFillTask from "src/hooks/api/tasks/useFillTask";
import useTaskForm from "src/hooks/api/tasks/useTaskForm";
import useTaskFormSteps from "src/hooks/api/tasks/useTaskFormSteps";
import useAuth from "src/hooks/selectors/useAuth";
import useWindowSize from "src/hooks/useWindowSize";

export default function CreateTaskView() {
	const { t } = useTranslation("task-view", {
		keyPrefix: "create-view",
	});
	const { t: translatedStep } = useTranslation("tasks", {
		keyPrefix: "steps",
	});
	const { id } = useParams() as any;
	const title = t(id ? "title-edit" : "title-create");
	const auth = useAuth();
	const { task, status, current, actions } = useTaskForm();
	const { isPhone } = useWindowSize();
	const { steps, isFinal, currentStep } = useTaskFormSteps();
	const { fill, listeners } = useFillTask();

	useEffect(() => {
		fill();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, listeners);

	// if (!auth.has_subscription) {
	// 	return (
	// 		<Layout {...{ title }} backUrl="/tasks">
	// 			<div className="flex flex-1 flex-col gap-4 justify-center items-center text-center">
	// 				<h2>{t("no-subscription.title")}</h2>
	// 				<p className="text-center max-w-md">
	// 					{t("no-subscription.description")}
	// 				</p>
	// 				<Button className="mt-4" to={"/subscription"}>
	// 					{t("no-subscription.button")}
	// 				</Button>
	// 			</div>
	// 		</Layout>
	// 	);
	// }

	return (
		<Layout
			{...{ title }}
			container="xxl"
			backUrl="/tasks"
			loading={status !== "idle"}
		>
			{auth?.company?.promos?.includes("christmas_gift") && (
				<ChristmasGiftBanner />
			)}
			<div className="grid lg:grid-cols-3 gap-6 mt-4 pb-32 w-full mx-auto">
				<div className="col-span-3">
					<Breadcrumb
						variant="dark"
						routes={[
							{
								to: "/tasks",
								label: t("breadcrumb.tasks"),
							},
							{
								to: "/tasks/create",
								label: t("breadcrumb.create"),
							},
						]}
					/>
				</div>

				{(isPhone || task.student) && (
					<div className="lg:col-span-3">
						<StepsLine
							onClick={(step: any) => {
								actions.updateCurrent(step);
							}}
							variant="horizontal-transparent"
							active={current}
							{...{ steps }}
						/>
					</div>
				)}
				{status !== "filling" ? (
					<Card className="lg:col-span-2 bg-background">
						{currentStep === translatedStep("description") && (
							<TaskForm.Description />
						)}
						{currentStep === translatedStep("budget") && (
							<TaskForm.Budget
								amountLocked={task?.student_hid ? true : false}
							/>
						)}
						{currentStep === translatedStep("shifts") && (
							<TaskForm.Subtasks />
						)}
						{currentStep === translatedStep("location") && (
							<TaskForm.Address />
						)}
						{currentStep === translatedStep("date") && (
							<TaskForm.Target />
						)}
						{currentStep === translatedStep("invite") && (
							<TaskForm.StudentPool />
						)}
						{currentStep === translatedStep("form") && (
							<TaskForm.Interview />
						)}
						{isFinal && <TaskForm.Summary />}
					</Card>
				) : (
					<Card className="lg:col-span-2">
						<div className="flex flex-col flex-1 p-4 justify-center items-center">
							<span className="base-loader"></span>
						</div>
					</Card>
				)}
				{task.student && <StudentBanner profile={task.student} />}
				{!isPhone && !task.student && (
					<StepsLine
						onClick={(step: any) => {
							actions.updateCurrent(step);
						}}
						theme="dark"
						variant="vertical"
						active={current}
						{...{ steps }}
					/>
				)}
			</div>
		</Layout>
	);
}
