import Badge from "src/components/Badge";
import Button from "src/components/Button";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import { formatPrice } from "src/lib/formatters";
import { cn } from "src/lib/utils";

export type JobOfferTask = {
	id: string | number;
	title: string;
	description: string;
	hour_rate: number;
	estimated_duration: number;
};

interface JobOfferTaskItemProps extends Translation {
	task: JobOfferTask;
	onDelete: () => void;
}

const JobOfferTaskItem = ({ t, task, onDelete }: JobOfferTaskItemProps) => {
	const hasBudget = !!task.estimated_duration && task.hour_rate;
	const hasDuration = !!task.estimated_duration;
	return (
		<div
			className={cn(
				"flex flex-col p-4 rounded-md bg-[#ffffff] shadow gap-2 border border-border"
			)}
		>
			<div className="flex gap-6 justify-between">
				<p className="line-clamp-2 flex-1">{task.title}</p>
				<div className="flex items-center gap-2">
					{hasBudget && (
						<Badge>
							{formatPrice(
								task.estimated_duration * task.hour_rate
							)}
						</Badge>
					)}
					{hasDuration && (
						<Badge variant="gray">
							{task.estimated_duration} {t("hour")}
						</Badge>
					)}
				</div>
			</div>

			<div className={cn("opacity-70 empty:hidden")}>
				{task.description}
			</div>

			{!!task.description && (
				<div className="flex gap-2 items-center mt-2">
					<Button onClick={onDelete} type="border" xsmall>
						<i className="far fa-trash"></i>
						{t("delete")}
					</Button>
				</div>
			)}
		</div>
	);
};

JobOfferTaskItem.locale = {
	nl: {
		hour: "uur",
		delete: "Verwijder",
	},
	en: {
		hour: "hour",
		delete: "Delete",
	},
};

export default withTranslation(JobOfferTaskItem);
